import React, { Component } from "react";
import queryString from "query-string";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// seo
import Seo from "../components/Seo";

// hook
import { useBookPackQuery } from "../hooks/useBookPackQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// services
import { sendToCT } from "../services/Clevertap";
import { sendToLSQ } from "../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../services/SendEmail";

// constant
import constant from "../constants";

// utils
import { validateServiceabilityBookPack } from "../utils";

// componentts
import BookPackHeading from "../components/BookPack/BookPackHeading";
import BookPackForm from "../components/BookPack/BookPackForm";

// api actions
import {
  getClientDetails,
  handlePaymentOfBookPack,
} from "../actions/bookPackActions";

class BookPackWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      clintInfo: {},
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      pincodeCategory: "",
    };
  }

  // Function -> To fetch the client details
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      const response = await getClientDetails(clientId);
      this.setState({ clintInfo: response.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  componentDidMount() {
    const annualPlan = this.props.bookPack.annualPlan[0];

    annualPlan &&
      this.setState({
        planCode: annualPlan.planCode,
        planPrice: Number(annualPlan.planPrice),
        planDay: annualPlan.planDay,
        type: annualPlan.planType,
        productId: annualPlan.productId,
        annualPlanPrice: Number(annualPlan.planPrice),
      });

    const { clientId } = queryString.parse(this.props.location.search);

    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  // Function -> To handle the payment of book pack
  handlePayNow = async values => {
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // leadSquared params
    let leadSquaredParams = {
      FirstName: values.name,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "book_pack_webinar",
      mx_Latest_Source: "New Website",
    };

    // payment v3
    let paymentBody = {
      firstName: values.name.split(" ")[0] || values.name,
      lastName: values.name.split(" ")[1] || "",
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      languageList: languageArr,
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    try {
      this.setState({ loading: true });
      // LSQ Call
      await sendToLSQ(leadSquaredParams);
      // Zoho Payment Call
      const response = await handlePaymentOfBookPack(paymentBody);
      const paymentUrl = response.results[0].url;

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated Webinar", {
        action: "BookPack_Webinar_Buy_Now Clicked",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        coupon: this.state.coupon,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error || {},
        "/marketing/v3/prospect"
      );
    }
  };

  // This function executes when we apply coupon
  handleCouponUpdate = (planPrice, coupon) => {
    if (coupon) {
      this.setState({
        showConfetti: true,
        planPrice: Number(planPrice),
        coupon: coupon,
        couponApplied: true,
        annualPlanPrice: Number(planPrice),
      });

      setTimeout(
        function () {
          this.setState({ showConfetti: false });
        }.bind(this),
        5000
      );
    }
  };

  // This function executes when we remove the applied coupon
  removedCoupon = () => {
    const { selectedPlan } = this.state;
    const plan = this.props.bookPack[selectedPlan][0];
    const pincodeCategory = this.state.pincodeCategory;

    // update state based on pincode category ie GOLD(serviceable) or SILVER(Remote)
    // when coupon is removed
    if (pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I || pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II) {
      const data =
        this.props.bookPack.annualPlan[0][pincodeCategory].split("#");

      this.setState({
        planCode: data[0],
        planPrice: Number(data[1]),
        annualPlanPrice: Number(data[1]),
        couponApplied: false,
        coupon: "",
      });
    } else {
      this.setState({
        planCode: plan.planCode,
        planPrice: Number(plan.planPrice),
        planDay: plan.planDay,
        type: plan.planType,
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(plan.planPrice),
      });
    }
  };

  // This function updates the planCode and planPrice
  // based on the pincode category i.e GOLD(serviceable) or SILVER(Remote) only
  // and for PLATINUM(non-serviceable) category planPrice and planCode will not get updated.
  handlePincodePricing = async category => {
    try {
      const planData = await validateServiceabilityBookPack(
        category,
        this.props.bookPack.annualPlan[0]
      );

      if (planData.categoryVal) {
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice),
          annualPlanPrice: Number(planData.planPrice),
          pincodeCategory: category,
        });
      } else {
        // for PLATINUM category(Non-serviceable) pincode pricing will get updated to default value.
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice),
          annualPlanPrice: Number(planData.planPrice),
          pincodeCategory: category,
        });
      }
    } catch (error) {
      console.error("Error in getting plan details based on pincode", error);
    }
  };

  render() {
    const {
      annualPlanPrice,
      clintInfo,
      loading,
      productId,
      planPrice,
      planDay,
      selectedPlan,
      serverError,
    } = this.state;

    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div className="bgLightgray" style={{ minHeight: "92vh" }}>
        <Seo
          title="Reverse Diabetes, Obesity, PCOS & More"
          description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
        />

        {appHeader === "mobile" && <AppHeader text="BOOK PACK" isBack={true} />}

        {/* Heading */}
        <BookPackHeading />

        {/* Form */}
        <BookPackForm
          price={planPrice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          handleCouponUpdate={this.handleCouponUpdate}
          coupon={this.state.coupon}
          removedCoupon={this.removedCoupon}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          planDay={planDay}
          handlePlan={this.handlePlan}
          showCouponSection={false}
          windowSize={this.props.windowSize}
          handlePincodePricing={this.handlePincodePricing}
          leadSquaredParam="book_pack_webinar"
        />
        <div className="mt-2 pb-3 text-center text-danger">
          {serverError} &nbsp;
        </div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayload = useBookPackQuery("webinar");
  const windowSize = useWindowSize();
  return (
    <BookPackWebinar
      {...props}
      bookPack={bookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
